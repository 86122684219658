import { RefObject, useCallback, useEffect, useRef } from 'react';
import styled from '@emotion/styled';

import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { breakPoints } from 'constant/breakPoints';
import { RectangleBlur } from 'styles/styled';

function Counter({
  projectDelivered,
  productLaunch,
  yearExp,
}: {
  projectDelivered: number;
  productLaunch: number;
  yearExp: number;
}): JSX.Element {
  const selfRef = useRef<HTMLElement>(null);

  const countRef1 = useRef<HTMLSpanElement>(null);
  const countRef2 = useRef<HTMLSpanElement>(null);
  const countRef3 = useRef<HTMLSpanElement>(null);

  const entry = useIntersectionObserver(selfRef, {
    threshold: 0.25,
    freezeOnceVisible: true,
  });

  const handleNumberAnimate = useCallback(
    (
      targetRef: RefObject<Element>,
      start: number,
      end: number = 0,
      duration: number,
    ) => {
      let startTimestamp: number | null = null;
      const step = (timestamp: number) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        targetRef.current &&
          (targetRef.current.innerHTML =
            Math.floor(progress * (end - start) + start) + '');
        if (progress < 1) {
          window.requestAnimationFrame(step);
        } else {
          window.cancelAnimationFrame(window.requestAnimationFrame(step));
        }
      };
      window.requestAnimationFrame(step);
    },
    [],
  );

  const handleStartAnim = useCallback(() => {
    const isAppearInViewPort = !!entry?.isIntersecting;
    if (!isAppearInViewPort) return;

    handleNumberAnimate(countRef1, 0, projectDelivered, 1000);
    handleNumberAnimate(countRef2, 0, productLaunch, 1500);
    handleNumberAnimate(countRef3, 0, yearExp, 1500);
  }, [
    entry?.isIntersecting,
    handleNumberAnimate,
    productLaunch,
    projectDelivered,
    yearExp,
  ]);

  useEffect(() => {
    handleStartAnim();
  }, [handleStartAnim]);

  return (
    <Wrap className="layout-wrap" ref={selfRef}>
      <RectangleBlur width="285px" rotate={39.24} top="80px" right="-190px" />
      <RectangleBlur
        width="285px"
        rotate={-141.97}
        bottom="-340px"
        left="-180px"
      />
      <CountItem className="text-center">
        <h4 className="font-semibold">
          <span ref={countRef1}>0</span> +
        </h4>
        <p className="font-normal">Projects Delivered</p>
      </CountItem>
      <CountItem className="text-center">
        <h4 className="font-semibold">
          <span ref={countRef2}>0</span> +
        </h4>
        <p className="font-normal">Products Launched</p>
      </CountItem>
      <CountItem className="text-center">
        <h4 className="font-semibold">
          <span ref={countRef3}>0</span> +
        </h4>
        <p className="font-normal">Years of Experience</p>
      </CountItem>
    </Wrap>
  );
}

export default Counter;

const Wrap = styled.section`
  justify-items: center;
  display: grid;
  position: relative;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  padding-top: 230px;
  padding-bottom: 50px;

  & > div:nth-of-type(1),
  & > div:nth-of-type(2) {
    display: none;
  }

  @media screen and (max-width: ${breakPoints.tabletS}px) {
    padding-top: 130px;
    padding-bottom: 0;
  }

  @media screen and (max-width: ${breakPoints.tabletL}px) {
    & > div:nth-of-type(1),
    & > div:nth-of-type(2) {
      display: block;
    }
  }
`;

const CountItem = styled.div`
  width: 100%;

  h4 {
    font-size: 56px;
    margin: 0;
  }

  p {
    font-size: 24px;
    color: var(--gray3);
  }

  span {
    font-variant-numeric: tabular-nums; // css trick for counter number smooth
  }

  @media screen and (max-width: ${breakPoints.tabletM}px) {
    margin-bottom: 24px;

    h4 {
      font-size: 40px;
    }

    p {
      font-size: 16px;
    }
  }

  @media screen and (min-width: ${breakPoints.tabletM}px) {
    position: relative;

    &:not(:last-child) {
      &:after {
        content: '';
        position: absolute;
        height: 90px;
        width: 2px;
        right: -1px;
        background-color: #86838c;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
`;
